<template>
  <div>
    <DarkModeSwitcher />
    <MobileMenu />
    <!-- <TopBar /> -->
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav relative">
        <!-- BEGIN: Logo -->
        <div class="flex flex-col justify-center items-center">
          <img
            v-if="!darkMode"
            src="https://firebasestorage.googleapis.com/v0/b/nfc-menu-system.appspot.com/o/Images%2Flogo%2FlogoLight.8cdf01e0.svg?alt=media&token=c5fe6af4-25aa-4e24-bd70-d390d25cb5f4"
            alt="Icewall Tailwind HTML Admin Template"
            class="w-20 mt-4"
          />
          <img
            v-else
            src="https://firebasestorage.googleapis.com/v0/b/nfc-menu-system.appspot.com/o/Images%2Flogo%2Flogo.bdd48fd9.svg?alt=media&token=89d1c9d2-9c7c-40be-a923-01e4de7ec68f"
            alt="Icewall Tailwind HTML Admin Template"
            class="w-20 mt-4"
          />
          <span class="text-black dark:text-white font-bold">NFC-Menu-System</span>
        </div>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title mr-2">
                  {{ i18n(`menu.${menu.title}`) }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
        <div class="ml-8 mb-10 runProf">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/home-food-plus.appspot.com/o/admin%2Fpublic%2Frunprof.svg?alt=media&token=92e71269-efea-4a44-b024-80f6b5c5730a"
            alt=""
          />
        </div>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      darkMode,
      router,
      linkTo,
      enter,
      leave
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss">
.runProf {
  position: absolute;
  // margin-bottom: auto !important;
  bottom: 0%;
  left: 3%;
  @media only screen and (max-width: 1280px) {
    width: 80px;
    left: -38%;
    // display: none;
  }
}

[dir='rtl'] .runProf {
  position: absolute;
  bottom: 0%;
  right: 20% !important;
  @media only screen and (max-width: 1280px) {
    width: 80px;
    right: 0% !important;
    // display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 80px;
    left: 87% !important;
    // display: none;
  }
}

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:before {
  content: '';
  transform: rotate(180deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:after {
  content: '';
  transform: rotate(270deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}
[dir='rtl']
  .side-nav
  > ul
  > li
  > .side-menu.side-menu--active
  .side-menu__icon:before {
  content: '';
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav .side-menu {
  padding-right: 1.25rem !important;
}

[dir='rtl'] .side-nav > div {
  @media only screen and (max-width: 1280px) {
    justify-content: end;
  }
}

[dir='rtl'] .side-nav > div.side-nav__devider {
  @media only screen and (max-width: 1280px) {
    transform: translateX(28%);
  }
}

.scale-up {
  @media only screen and (max-width: 1280px) {
    max-width: 150%;
  }
}
</style>
